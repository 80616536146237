export default class Constants {
    static TAB_INDEX_KEY = "tab";
    static SEARCH_QUERY_KEY = "search_query";
    static DEBUG_MODE_KEY = "debug_mode";
    static LIVE_MODE_KEY = "live_mode";
    static START_AT_KEY = "start_at";
    static END_AT_KEY = "end_at";
    static LOG_MODE_KEY = "log_mode";

    static SIZE_KEY = "size";
    static PAGE_KEY = "page";
    static SORT_KEY = "sort";
}
