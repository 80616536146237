import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/en";
import "dayjs/locale/en-gb";
import "dayjs/locale/da";
import "dayjs/locale/de";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import "dayjs/locale/sv";
import utc from "dayjs/plugin/utc";

const LANGUAGE_KEY = "user_language";

dayjs.extend(localizedFormat);
dayjs.extend(utc);
const getInitialLocale = (): string => {
    if (typeof sessionStorage !== "undefined") {
        const savedLocale = sessionStorage.getItem(LANGUAGE_KEY);
        if (savedLocale) {
            return savedLocale === "en_US" ? "en" : savedLocale === "en" ? "en-gb" : savedLocale;
        }
    }
    return "en";
};

dayjs.locale(getInitialLocale());

export const setDayjsLocale = (locale: string) => {
    const normalizedLocale = locale === "en_US" ? "en" : locale === "en" ? "en-gb" : locale;
    dayjs.locale(normalizedLocale);
    if (typeof sessionStorage !== "undefined") {
        sessionStorage.setItem(LANGUAGE_KEY, locale);
    }
};
