import BlockIcon from "@mui/icons-material/Block";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export type ErrorPageType = "403" | "404" | "404-resource" | "500" | "default";

interface ErrorConfig {
    icon: React.ReactNode;
    titleKey: string;
    subtitleKey: string;
    actionKey: string;
}

type ErrorConfigurations = {
    [key in ErrorPageType]: ErrorConfig;
};

function ErrorPage({ type = "404", error = undefined }: { type?: ErrorPageType; error?: unknown }) {
    const { t } = useTranslation(); // Get translation function

    const errorConfig: ErrorConfigurations = {
        "403": {
            icon: <BlockIcon sx={{ fontSize: 96, color: "error.main" }} />,
            titleKey: "error.403.title",
            subtitleKey: "error.403.subtitle",
            actionKey: "error.403.action",
        },
        "404": {
            icon: <ErrorOutlineIcon sx={{ fontSize: 96, color: "primary.main" }} />,
            titleKey: "error.404.title",
            subtitleKey: "error.404.subtitle",
            actionKey: "error.404.action",
        },
        "404-resource": {
            icon: <SearchOffIcon sx={{ fontSize: 96, color: "info.main" }} />,
            titleKey: "error.404-resource.title",
            subtitleKey: "error.404-resource.subtitle",
            actionKey: "error.404-resource.action",
        },
        "500": {
            icon: <CloudOffIcon sx={{ fontSize: 96, color: "warning.main" }} />,
            titleKey: "error.500.title",
            subtitleKey: "error.500.subtitle",
            actionKey: "error.500.action",
        },
        default: {
            icon: <WarningAmberIcon sx={{ fontSize: 96, color: "warning.main" }} />,
            titleKey: "error.default.title",
            subtitleKey: "error.default.subtitle",
            actionKey: "error.default.action",
        },
    };

    const config = errorConfig[type] || errorConfig.default;

    const handleAction = (): void => {
        window.location.href = "/";
    };

    return (
        <Box
            sx={{
                minHeight: "70vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 4,
            }}
        >
            <Stack spacing={4} alignItems="center" maxWidth="lg">
                {config.icon}
                <Typography variant="h2" component="h1" textAlign="center" fontWeight="bold" color="text.primary">
                    {t(config.titleKey)}
                </Typography>
                <Typography variant="h6" textAlign="center" color="text.secondary" maxWidth="sm">
                    {t(config.subtitleKey)}
                </Typography>
                <Button variant="contained" size="large" onClick={handleAction} sx={{ mt: 2 }}>
                    {t(config.actionKey)}
                </Button>
            </Stack>
        </Box>
    );
}

export default ErrorPage;
