import * as Sentry from "@sentry/react";
import MontaException from "app/features/common/model/MontaException";
import getErrorMessage from "app/features/common/util/MontaResponseUtil";
import Utils from "app/features/common/util/Utils";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

export default class ErrorUtil {
    /**
     * Handles errors and displays error message using snackbar.
     *
     * @param  e - The error object.
     * @param enqueueSnackbar - Function to enqueue the snackbar.
     * @param throwOnError
     */
    static HandleError(
        e: unknown,
        enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
        throwOnError?: boolean
    ) {
        console.log("Error", e);

        if (e instanceof Error) {
            let errorMessage = "Unknown exception occurred";

            // If the error is a MontaException, check if it's a permission error
            if (e instanceof MontaException) {
                // If it's a permission error, return early because we will reroute the user to the login page
                if (ErrorUtil.checkPermission(e)) {
                    return;
                }
            }

            // If throwOnError is true, throw the error to the caller
            if (throwOnError) {
                // This will allow our error boundary to catch the error and display the appropriate error page
                throw e;
            } else {
                // Otherwise we should bubble the error to the user via a snackbar
                if (e instanceof MontaException) {
                    const responseMessage = getErrorMessage(e.montaResponse);
                    if (responseMessage) {
                        errorMessage = responseMessage;
                    }
                } else {
                    Sentry.captureException(e);
                    errorMessage = e.message;
                }
            }
            enqueueSnackbar(errorMessage, { variant: "error" });
        }
    }

    static checkPermission(montaException: MontaException): boolean {
        if (montaException.statusCode === 401) {
            window.location.href = Utils.getKratosLoginUrl();
            return true;
        }

        return false;
    }
}
