import { Balance, EvStation } from "@mui/icons-material";
import { Box, CssBaseline, Stack } from "@mui/material";
import { DashboardLayout, Navigation, NotificationsProvider } from "@toolpad/core";
import { ReactRouterAppProvider } from "@toolpad/core/react-router";
import { ModalType } from "app/hooks/useModal.ts";
import useShowModal from "app/hooks/useShowModal.ts";
import HttpErrorBoundary from "lib/layout/HttpErrorBoundary.tsx";
import SettingsModal from "lib/settings/components/SettingsModal.tsx";
import SettingsUserAvatar from "lib/settings/components/SettingsUserAvatar.tsx";
import MontaThemeSwitchButton from "lib/theme/components/MontaThemeSwitchButton.tsx";
import getMontaTheme from "lib/theme/theme.ts";
import useIsUserAdmin from "lib/user/hooks/useIsUserAdmin.ts";
import React, { Suspense, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { useSettingsContext } from "lib/settings/hooks/useSettingsContext.ts";
import { setDayjsLocale } from "lib/config/dayjs.ts";

function getNavigation(): Navigation {
    const isUserAdmin = useIsUserAdmin();
    return useMemo(() => {
        const navigation: Navigation = [
            {
                kind: "header",
                title: "Main",
            },
            {
                segment: "charge-points",
                title: "Charge Points",
                icon: <EvStation />,
            },
        ];
        if (isUserAdmin) {
            navigation.push(
                {
                    kind: "header",
                    title: "Admin",
                },
                {
                    segment: "load-balancing/load-balancing-groups",
                    title: "Load Balancing Groups",
                    icon: <Balance />,
                }
            );
        }
        return navigation;
    }, [isUserAdmin]);
}

function MontaLogo() {
    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 1, ml: 2, mr: 2 }}>
            <img src="/logo.svg" height={25} width="100%" alt="Monta logo" />
        </Box>
    );
}

function AppToolbar() {
    const { showModal } = useShowModal();
    return (
        <>
            <Stack direction="row" alignItems="center" spacing={1}>
                <MontaThemeSwitchButton />
                <SettingsUserAvatar
                    onClick={() => {
                        showModal(ModalType.Settings);
                    }}
                />
            </Stack>
            <SettingsModal />
        </>
    );
}

function MontaAppLayout() {
    const navigation = getNavigation();
    const { language } = useSettingsContext();
    setDayjsLocale(language ?? "en");
    return (
        <ReactRouterAppProvider
            navigation={navigation}
            theme={getMontaTheme()}
            branding={{
                title: "CPI Tool",
                logo: <MontaLogo />,
            }}
        >
            <CssBaseline />
            <NotificationsProvider>
                <DashboardLayout
                    defaultSidebarCollapsed={true}
                    slots={{
                        toolbarActions: AppToolbar,
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            maxWidth: "100vw", // Keep content within viewport width
                            overflowX: "hidden", // Prevent horizontal overflow
                            overflowY: "auto", // Allow vertical scrolling if necessary
                            display: "flex", // Ensure content flexes within Box
                            flexDirection: "column", // Stack children vertically within Box
                        }}
                    >
                        <Suspense fallback={<></>}>
                            <HttpErrorBoundary>
                                <Outlet />
                            </HttpErrorBoundary>
                        </Suspense>
                    </Box>
                </DashboardLayout>
            </NotificationsProvider>
        </ReactRouterAppProvider>
    );
}

export default MontaAppLayout;
