import MontaAppLayout from "lib/layout/MontaAppLayout.tsx";
import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";

const ChargePage = lazy(() => import("app/pages/ChargePage.tsx"));
const ChargePointPage = lazy(() => import("app/pages/ChargePointPage.tsx"));
const ChargePointsPage = lazy(() => import("app/pages/ChargePointsPage.tsx"));
const LoadBalancingGroupCreatePage = lazy(() => import("app/pages/load-balancing-groups/LoadBalancingGroupCreatePage.tsx"));
const LoadBalancingGroupUpdatePage = lazy(() => import("app/pages/load-balancing-groups/LoadBalancingGroupUpdatePage.tsx"));
const LoadBalancingGroupPage = lazy(() => import("app/pages/load-balancing-groups/LoadBalancingGroupPage.tsx"));
const LoadBalancingGroupsPage = lazy(() => import("app/pages/load-balancing-groups/LoadBalancingGroupsPage.tsx"));

function MontaAppRouter() {
    return (
        <Routes>
            <Route path="/" element={<MontaAppLayout />}>
                <Route index element={<ChargePointsPage />} />
                <Route path="charge-points" element={<ChargePointsPage />} />
                <Route path="charge-points/:identity" element={<ChargePointPage />} />
                <Route path="charges/:chargeId" element={<ChargePage />} />
                {/*Load Balancing Domain*/}
                <Route path="/load-balancing/load-balancing-groups/create" element={<LoadBalancingGroupCreatePage />} />
                <Route
                    path="/load-balancing/load-balancing-groups/:loadBalancingGroupId/update"
                    element={<LoadBalancingGroupUpdatePage />}
                />
                <Route path="/load-balancing/load-balancing-groups/:loadBalancingGroupId" element={<LoadBalancingGroupPage />} />
                <Route path="/load-balancing/load-balancing-groups" element={<LoadBalancingGroupsPage />} />
                <Route path="/load-balancing/operators/:operatorId/load-balancing-groups" element={<LoadBalancingGroupsPage />} />
                <Route path="/load-balancing/teams/:teamId/load-balancing-groups" element={<LoadBalancingGroupsPage />} />
                {/*Error Pages*/}
                <Route path="*" element={<ErrorPage type="404" />} />
            </Route>
        </Routes>
    );
}

export default MontaAppRouter;
