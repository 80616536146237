import { createTheme, Theme } from "@mui/material/styles";
import * as colors from "lib/theme/util/colors";

const baseFontStack = [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
];

const PrimaryFont = ['"Instrument Sans", sans-serif'].concat(baseFontStack).join(",");

function getMontaTheme(): Theme {
    return createTheme({
        cssVariables: {
            colorSchemeSelector: "data-toolpad-color-scheme", // want to start using this
        },
        colorSchemes: {
            light: {
                palette: {
                    primary: colors.primary,
                    secondary: colors.secondary,
                    text: {
                        primary: "#343457",
                    },
                },
            },
            dark: {
                palette: {
                    primary: colors.primary,
                    secondary: colors.secondary,
                    text: {
                        primary: "#FFFFFF",
                    },
                },
            },
        },
        typography: {
            htmlFontSize: 10,
            fontFamily: PrimaryFont,
            fontWeightBold: 700,
            fontWeightMedium: 500,
            fontWeightRegular: 400,
            allVariants: {
                fontFamily: PrimaryFont,
            },
            h1: {
                fontWeight: 700,
                fontSize: "4rem",
            },
            h2: {
                fontWeight: 700,
                fontSize: "3.5rem",
            },
            h3: {
                fontWeight: 700,
                fontSize: "3rem",
            },
            h4: {
                fontWeight: 700,
                fontSize: "2.5rem",
            },
            h5: {
                fontWeight: 700,
                fontSize: "2rem",
            },
            subtitle1: {
                fontWeight: 700,
                fontSize: "1.6rem",
            },
            subtitle2: {
                fontWeight: 500,
                fontSize: "1.5rem",
            },
            body1: {
                fontWeight: 400,
                fontSize: "1.4rem",
            },
            body2: {
                fontSize: "1.4rem",
                fontWeight: 700,
            },
            button: {
                textTransform: "none",
                fontSize: "1.4rem",
                fontWeight: 700,
                lineHeight: "1.6rem",
            },
            overline: {
                fontSize: "1.2rem",
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: `
                    html, body, #__next {
                      overscroll-behavior: none;
                    }
            
                    div[scale="1"] {
                      width: 100%;
                    }
            
                    html {
                      font-size: 62.5%;
                    }
            
                    body {
                      font-family: 'Instrument Sans', sans-serif;
                      font-weight: 400;
                      overflow-y: auto;
                    }
                `,
            },
            MuiButton: {
                defaultProps: {
                    color: "primary",
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        boxShadow: "none",
                        width: "100%",
                        borderRadius: "8px",
                        borderWidth: "1",
                        boxSizing: "border-box",
                        position: "relative",
                        "&:before": {
                            content: '""',
                            position: "absolute",
                            left: "0",
                            right: "0",
                            top: "0",
                            bottom: "0",
                            zIndex: 0,
                            borderStyle: "solid",
                            pointerEvents: "none",
                            borderRadius: "8px",
                            borderWidth: "1px",
                        },
                        ...theme.applyStyles("light", {
                            borderColor: "#E2E6F0",
                            "&:before": {
                                borderColor: "#E2E6F0",
                            },
                        }),
                        ...theme.applyStyles("dark", {
                            borderColor: "transparent",
                            "&:before": {
                                borderColor: "transparent",
                            },
                        }),
                    }),
                },
            },
        },
    });
}

export default getMontaTheme;
